
	import { Component, Vue, Watch } from "vue-property-decorator";
	import request from "@/request";
	import G2 from "@antv/g2";
	import moment from "moment";
	import { DatePicker, Form, FormItem, Option, Select } from "view-design";
	import CountUp from "@/components/countup/countup.vue";
	import Loading from "@/components/loading/Loading.vue";
	import axios from "axios";
	import { Route } from "vue-router";

	const CancelToken = axios.CancelToken;

	interface MedicalDeviceData {
		readonly name:string,
		readonly id:string,
		peak:number,
		average:string,
		chart:G2.Chart | "",
		loading:boolean,
		show:boolean,
		count:number
	}

	@Component({
		components: {
			DatePicker,
			Option,
			Select,
			CountUp,
			FormItem,
			Form,
			Loading
		}
	})
	export default class MedicalDevice extends Vue {
		devices:MedicalDeviceData[] = [];
		date:Date = new Date();
		type:string = "日";
		times:number = 0;
		private MainLink:HTMLLinkElement = document.createElement("link");

		get pickerType ():string {
			const type = this.type;
			if (type === "日") {
				return "date";
			} else if (type === "年") {
				return "year";
			} else if (type === "月") {
				return "month";
			} else {
				return "date";
			}
		}

		get usedDevice ():number {
			return this.devices.filter(t => t.show).length;
		}

		cancellation:any = () => void 0;

		renderLine () {
			const time = moment(this.date).format("YYYY-MM-DD_" + this.type);
			this.times = 0;
			this.cancellation("new request");
			const source = CancelToken.source();
			this.cancellation = source.cancel;
			this.devices.forEach(t => {
				t.loading = true;
				t.show = true;
				// @ts-ignore
				t.chart && t.chart.hide();
				// @ts-ignore
				t.chart && t.chart.hide();
				request({
					url: "/his/rest/equipment/",
					params: {
						LOCATION: t.name,
						time,
						ordering: "create_datetime,HOUR",
						pagesize: 1000
					},
					cancelToken: source.token
				}).then(({ data }) => {
					t.loading = false;
					if (data.count > 0) {
						t.show = true;
					} else {
						t.show = false;
						return;
					}
					if (!t.chart) {
						const chart = new G2.Chart({
							container: t.id,
							height: 200,
							forceFit: true,
							padding: [ 20, 40, 40, 60 ]
						});
						chart.scale("value", {
							range: [ 0, 1 ],
							alias: "使用量次数"
						});
						chart.axis("time", {
							label: {
								textStyle: {
									fill: "#fff"
								}
							}
						});
						chart.axis("value", {
							label: {
								textStyle: {
									fill: "#FFF"
								}
							},
							title: {
								autoRotate: true,
								offset: 30,
								textStyle: {
									fontSize: 12
								}
							}
						});
						t.chart = chart;
					}
					const chart = t.chart;
					let dataSource:{ time:string, value:number }[] = this.getTImeLine(this.type);
					const timeDisplay = this.type === "日" ? "HOUR" : this.type !== "年" ? "create_datetime" : "";
					let total:number = 0;
					let peak:number = 0;
					let count:number = 0;
					if (timeDisplay) {
						data.results.forEach((t:any) => {
							const time:string = t[ timeDisplay ].toString();
							dataSource.find(t => t.time === time)!.value += t.CHECK_NUMBER;
							total += t.CHECK_NUMBER;
						});
					} else {
						data.results.forEach((t:any) => {
							const time = t.create_datetime.replace(/-\d\d$/, "");
							dataSource.find(t => t.time === time)!.value += t.CHECK_NUMBER;
							total += t.CHECK_NUMBER;
						});
					}
					let timeline:string[] = [];
					dataSource.forEach(t => {
						timeline.push(t.time);
						if (t.value > 0) {
							peak = Math.max(t.value, peak);
							count++;
						}
					});
					t.peak = peak;
					t.average = (total / count).toFixed(2);
					t.count = total;
					this.times += total;
					chart.source(dataSource);
					chart.scale("time", {
						range: [ 0, 1 ],
						type: this.type === "日" ? "linear" : "timeCat",
						value: timeline,
						tickCount: 4
					});
					chart.area().position("time*value").shape("smooth");
					chart.line().position("time*value").shape("smooth");
					// @ts-ignore
					chart.show();
					chart.repaint();
				});
			});
		}

		renderCss () {
			less.render("@infoBlockBackground: rgba(red(@baseColor), green(@baseColor)/2, blue(@baseColor)/2, .8);.brand {background-color: @infoBlockBackground;}", {
				javascriptEnabled: true,
				modifyVars: {
					"@baseColor": this.$store.state.baseColor,
					"path": "/static/UnityWeb/desktop/"
				},
				paths: [ "/static/UnityWeb/desktop/" ]
			}).then(data => {
				this.MainLink && this.MainLink.remove();
				const href:string = URL.createObjectURL(new Blob([ data.css ]));
				const link = document.createElement("link");
				link.href = href;
				link.rel = "stylesheet";
				document.head.appendChild(link);
				this.MainLink = link;
			});
		}

		getTImeLine (type:string):{ time:string, value:number }[] {
			let timeline:{ time:string, value:number }[] = [];
			if (type === "日") {
				for (let i = 0; i < 24; i++) {
					timeline.push({ time: i.toString(), value: 0 });
				}
			} else if (type === "月") {
				let base = moment(this.date).date(0);
				const count:number = moment(this.date).add(1, "M").date(0).get("D");
				for (let i = 0; i < count; i++) {
					timeline.push({ time: base.add(1, "d").format("YYYY-MM-DD"), value: 0 });
				}
			} else if (type === "周") {
				let base = moment(this.date).weekday(0).add(-1, "day");
				for (let i = 0; i < 7; i++) {
					timeline.push({ time: base.add(1, "d").format("YYYY-MM-DD"), value: 0 });
				}
			} else {
				let base = moment(this.date).month(-1);
				for (let i = 0; i < 12; i++) {
					timeline.push({ time: base.add(1, "M").format("YYYY-MM"), value: 0 });
				}
			}
			return timeline;
		}

		mounted ():void {
			this.renderCss();
			request({
				url: "/his/rest/equipment/devices/"
			}).then(({ data }) => {
				data.data.forEach((t:string, index:number) => {
					this.devices.push({
						id: "id" + index,
						name: t,
						peak: 0,
						average: "0.00",
						chart: "",
						loading: true,
						show: false,
						count: 0
					});
				});
				this.$nextTick(() => {
					this.renderLine();
				});
			});
		}

		@Watch("$store.state.baseColor")
		onColorChange () {
			this.renderCss();
		}

		beforeRouteLeave (from:Route, to:Route, next:Function):void {
			this.MainLink.remove();
			next();
		}
	}
